import React from "react"
import Layout from "../components/layout"
import MediumPost from "../components/mediumPost"
import VerticallyCenteredRow from "../components/verticallyCenteredRow"
import FadeIn from "../components/fadeIn"
import ShapeParticles from "../components/ShapeParticles"
import { graphql } from "gatsby"

export default function blog(props) {
  const posts = props.data.allMediumPost.edges
  var mostRecentSixPosts = posts.slice(0, 6)

  return (
    <div>
      <ShapeParticles />
      <Layout fixedFooter>
        <FadeIn>
          <Titles />
          <VerticallyCenteredRow style={{ flexWrap: "wrap" }}>
            {mostRecentSixPosts.map((post, index) => (
              <MediumPost post={post} />
            ))}
          </VerticallyCenteredRow>
        </FadeIn>
      </Layout>
    </div>
  )
}

const Titles = () => (
  <div>
    <h3 style={styles.aboutMeText}>BLOG</h3>
    <h1 style={styles.whoAmI}>RECENT ARTICLES</h1>
    <hr
      style={{
        marginTop: 30,
      }}
    />
  </div>
)

const styles = {
  smallVerticalMargin: {
    marginTop: 5,
    marginBottom: 5,
    width: "100%",
    textAlign: "center",
  },
  subtitle: {
    marginTop: 5,
    marginBottom: 5,
    width: "100%",
    textAlign: "center",
  },
  aboutMeText: {
    fontSize: "2rem",
    marginBottom: 0,
  },
  whoAmI: {
    fontSize: "3rem",
    marginTop: 10,
    marginLeft: 20,
    color: "#666",
  },
}

export const pageQuery = graphql`
  query {
    allMediumPost(limit: 5, sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          id
          createdAt
          title
          uniqueSlug
          author {
            name
            username
          }
          virtuals {
            subtitle
            previewImage {
              imageId
            }
          }
        }
      }
    }
  }
`
