import React from "react"

export default function mediumPost(props) {
  let post = props.post

  let imageSource = `https://cdn-images-1.medium.com/max/400/${
    post.node.virtuals.previewImage.imageId
  }`

  let articleSource = `https://medium.com/@${post.node.author.username}/${
    post.node.uniqueSlug
  }`

  return (
    <div style={styles.container} className="blogRow">
      <img style={styles.postImage} src={imageSource} alt="" />
      <div style={{ padding: 10 }}>
        <h3 style={styles.header}>{post.node.title}</h3>
        <h4 style={styles.subHeader}>{post.node.virtuals.subtitle}</h4>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <a href={articleSource}>
            <div style={styles.readMoreButton}>
              <h4 style={styles.readMoreText}>READ MORE</h4>
            </div>
          </a>
          <h5 style={styles.date}>{post.node.createdAt}</h5>
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    width: "45%",
    margin: "30px auto",
  },
  header: {
    marginBottom: 0,
    marginTop: 5,
  },
  subHeader: {
    marginTop: 3,
    marginBottom: 10,
    color: "#444",
  },
  postImage: {
    width: "100%",
    height: "auto",
    marginBottom: 0,
    borderRadius: 10,
    boxShadow: `5px 5px 40px rgba(0,0,0,.4)`,
  },
  date: {
    backgroundColor: "transparent",
    paddingTop: 0,
    marginTop: 30,
    color: "#444",
  },
  readMoreButton: {
    borderRadius: 40,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#444",
    maxWidth: 140,
  },
  readMoreText: {
    marginBottom: 0,
    marginTop: 0,
    padding: 15,
    backgroundColor: "transparent",
  },
}
